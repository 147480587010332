import get from 'lodash.get';
import { globalTranslations } from '@helpers/globalTranslations';
import { form } from '@DTO/sections/form/Form';
import { getDataFromPreview } from '@helpers/form';

export const calculatorDTO = (calculator, products, previewData?: unknown) => {
  const questionsFlat = get(calculator, 'calculators', []).map(calculatorObject => {
    const entity = calculatorObject.entity;
    return get(entity, 'fieldCalculatorQuestion', []).map(questionObject => get(questionObject, 'entity.id', ''))
  }).flat();

  const formId = get(calculator, 'fieldFormCalculator.entity.fieldForm.entity.uuid', '');

  return {
    type: 'calculator',
    products: products.map((product) => {
      return {
        title: get(product, 'title', ''),
        id: get(product, 'nid', ''),
        body: get(product, 'fieldSmallCard.entity.fieldCardCopyNotHtml', ''),
        image: {
          ...get(product, 'fieldSmallCard.entity.fieldImage', ''),
          webp: get(product, 'fieldSmallCard.entity.fieldImage.derivative.url', ''),
        },
        cta: {
          label: globalTranslations('fieldEstymatorCardCta'),
          url: get(product, 'path.alias', '/'),
        },
        price: get(product, 'fieldSmallCard.entity.fieldDevicePrice', ''),
        idCalculate: get(product, 'fieldSmallCard.entity.fieldIdCalculate', ''),
        maxRoi: get(product, 'fieldSmallCard.entity.fieldMax', null),
      };
    }),

    contact: get(calculator, 'fieldSavingsCalculatorContact', []).map((contactObject) => {
      const contact = contactObject.entity;
      return {
        id: get(contact, 'id', ''),
        title: get(contact, 'fieldHeadline.processed', ''),
        subtitle: get(contact, 'fieldCopy.processed', ''),
        cta: {
          label: get(contact, 'fieldLinkCta.title', ''),
          url: get(contact, 'fieldLinkCta.url.path', ''),
        },
      };
    }),

    calculators: get(calculator, 'calculators', []).map((calculatorObject) => {
      const entity = calculatorObject.entity;

      return {
        id: get(entity, 'id', ''),
        type: get(entity, 'entityBundle', ''),
        title: get(entity, 'fieldHeadline.processed', ''),
        description: get(entity, 'fieldCopy.processed', ''),
        questions: get(entity, 'fieldCalculatorQuestion', []).map((questionObject) => {
          const question = questionObject.entity;

          return {
            id: get(question, 'id', ''),
            title: get(question, 'fieldTitle', ''),
            options: get(question, 'fieldCalculatorQuestionItem', '').map((itemObject, optionIndex) => {
              const item = itemObject.entity;

              return {
                id: get(item, 'id', ''),
                title: get(item, 'fieldTitle', ''),
                subtitle: get(item, 'fieldSubtitle', ''),
                description: get(item, 'fieldCopy.processed', ''),
                icon: {
                  url: get(item, 'fieldIcon.url', ''),
                  alt: get(item, 'fieldIcon.alt', ''),
                  title: get(item, 'fieldIcon.title', ''),
                  webp: get(item, 'fieldIcon.derivative.url', ''),
                },
                averageCostPerImage: get(item, 'fieldAverageCostPerImageEu'),
                averageAmountOfImagesPerMonth: get(item, 'fieldAverageAmountOfImages'),
                averageOperatorCostPerImage: get(item, 'fieldAverageOperatorCost'),
                fixedCostPerMonth: get(item, 'fieldFixedCostPerMonth'),
                questionIndex: questionsFlat.findIndex(qItem => qItem === get(question, 'id', '')),
                optionIndex,
                firstProductId: get(item, 'fieldRecommendedProduct.entity.nid', null),
              };
            }),
          };
        }),
        infoBox: get(entity, 'fieldSavingsInfobox', undefined) ? {
          headline: get(entity, 'fieldSavingsInfobox.entity.fieldHeadline.processed', ''),
          description: get(entity, 'fieldSavingsInfobox.entity.fieldCopy.processed', ''),
          cta: get(entity, 'fieldSavingsInfobox.entity.fieldLinkCta', undefined) ? {
            label: get(entity, 'fieldSavingsInfobox.entity.fieldLinkCta.title', ''),
            url: get(entity, 'fieldSavingsInfobox.entity.fieldLinkCta.url.path', '/'),
          } : undefined,
        } : undefined
      };
    }),

    recommendDevices: {
      headline: get(calculator, 'fieldRecommendDevices.entity.fieldHeadline.processed', ''),
      description: get(calculator, 'fieldRecommendDevices.entity.fieldCopy.processed', ''),
    },

    form: {
      headline: get(calculator, 'fieldFormCalculator.entity.fieldHeadline.processed', ''),
      description: get(calculator, 'fieldFormCalculator.entity.fieldDescription.processed', ''),
      form: form(formId, getDataFromPreview(get(previewData, 'drupalData', undefined))),
      image: get(calculator, 'fieldFormCalculator.entity.fieldImage', undefined) ? {
        url: get(calculator, 'fieldFormCalculator.entity.fieldImage.url', ''),
        alt: get(calculator, 'fieldFormCalculator.entity.fieldImage.alt', ''),
        title: get(calculator, 'fieldFormCalculator.entity.fieldImage.title', ''),
        webp: get(calculator, 'fieldFormCalculator.entity.fieldImage.derivative.url', ''),
      } : undefined,
    },

    roiTranslations: {
      less6: 
        get(calculator, 'calculators[0].entity.fieldSavingsInfobox.entity.fieldLessThan6Months') ||
        get(calculator, 'calculators[1].entity.fieldSavingsInfobox.entity.fieldLessThan6Months', ''),
      '6to9':
        get(calculator, 'calculators[0].entity.fieldSavingsInfobox.entity.fieldLessThan9Months') ||
        get(calculator, 'calculators[1].entity.fieldSavingsInfobox.entity.fieldLessThan9Months', ''),
      '9to12':
        get(calculator, 'calculators[0].entity.fieldSavingsInfobox.entity.fieldLessThan1Year') ||
        get(calculator, 'calculators[1].entity.fieldSavingsInfobox.entity.fieldLessThan1Year', ''),
      '12to18':
        get(calculator, 'calculators[0].entity.fieldSavingsInfobox.entity.fieldLessThan15Year') ||
        get(calculator, 'calculators[1].entity.fieldSavingsInfobox.entity.fieldLessThan15Year', ''),
      '18to24':
        get(calculator, 'calculators[0].entity.fieldSavingsInfobox.entity.fieldLessThan2Years') ||
        get(calculator, 'calculators[1].entity.fieldSavingsInfobox.entity.fieldLessThan2Years', ''),
      '24to30':
        get(calculator, 'calculators[0].entity.fieldSavingsInfobox.entity.fieldLessThan25Years') ||
        get(calculator, 'calculators[1].entity.fieldSavingsInfobox.entity.fieldLessThan25Years', ''),
      '30to36':
        get(calculator, 'calculators[0].entity.fieldSavingsInfobox.entity.fieldLessThan3Years') ||
        get(calculator, 'calculators[1].entity.fieldSavingsInfobox.entity.fieldLessThan3Years', ''),
      'more36':
        get(calculator, 'calculators[0].entity.fieldSavingsInfobox.entity.fieldMoreThan3Years') ||
        get(calculator, 'calculators[1].entity.fieldSavingsInfobox.entity.fieldMoreThan3Years', ''),
    }
  };
};
