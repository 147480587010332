import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import PageComponents from '@components/layout/PageComponents/PageComponents';

// === Content === //
import { CalculatePageDTO } from '@DTO/CalculatePageDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface CalculatePageProps {
  pageContext?: TemplatePageContextData;
}

const CalculatePage = ({ pageContext }: CalculatePageProps) => {
  const calculatePage = CalculatePageDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(calculatePage.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={calculatePage.metaData}
        breadcrumbs={calculatePage.breadcrumbs}
        bannerContactInfo={calculatePage.stickedForm}
        navLangPath={calculatePage.langPath}
      >
        <PageComponents
          components={calculatePage.components}
          slug={`/${pageContext?.slug || ''}`}
        />
      </Layout>
    </>
  );
};

export default CalculatePage;
