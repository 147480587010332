import get from 'lodash.get';

// === Helpers === //
import { getNodeEntity } from '@utils';

// === Sections === //
import { mainBanner } from '@DTO/sections/MainBanner';
import { metatags } from '@DTO/sections/Metatags';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { calculatePageData } from '@graphql/CalculatorPage';
import { calculatorDTO } from '@DTO/sections/Calculator';
import { languagePath } from '@DTO/sections/LanguagePath';

export const CalculatePageDTO = (uuid: string | undefined, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const nodeQuery = calculatePageData();
    currentPageData = nodeQuery;
  } else {
    currentPageData = data;
  }

  const nodeEntity = getNodeEntity(uuid, currentPageData, data);
  const products = !data
    ? get(currentPageData, 'products.nodeQuery.entities', [])
    : get(currentPageData, 'drupalData.products.entities', []);


  const banner = mainBanner(nodeEntity, 'fieldMainBanner');
  banner.type = 'main_banner_variant_3';

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, []),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      banner,
      calculatorDTO(nodeEntity, products, data)
    ],
  };

  return {
    ...queryData
  }
};
