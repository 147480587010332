import { replaceLangCodes } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

export const calculatePageData = () => {
  const data = useStaticQuery(graphql`
    query calculatePageQueryAndCalculatorProducts {
      products: drupalData {
        nodeQuery(filter: { conditions: { field: "type", value: "product" } }, limit: 1000) {
          entities {
            ... on drupalData_NodeProduct {
              title
              fieldSmallCard {
                entity {
                  ...ParagraphProductSmallCard
                }
              }
              path {
                alias
              }
              nid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
      drupalData {
        nodeQuery(filter: { conditions: { field: "type", value: "calculate_page" } }) {
          entities {
            entityBundle
            ... on drupalData_NodeCalculatePage {
              entityBundle
              fieldMetatags {
                entity {
                  ...ParagraphMetatags
                }
              }
              path {
                alias
              }
              title
              uuid
              langcode {
                value
              }
              sticky: entityMultisiteEnabled
              status
              fieldMainBanner {
                entity {
                  ...ParagraphMainBannerVariant1
                }
              }
              fieldSavingsCalculatorContact {
                entity {
                  ... on drupalData_ParagraphSavingsCalculatorContact {
                    id
                    fieldCopy {
                      processed
                    }
                    fieldHeadline {
                      processed
                    }
                    fieldLinkCta {
                      title
                      url {
                        path
                      }
                    }
                  }
                }
              }
              calculators: fieldCalculatorQuestion {
                entity {
                  ... on drupalData_ParagraphCalculatorQuestion {
                    id
                    entityBundle
                    fieldCopy {
                      processed
                    }
                    fieldHeadline {
                      processed
                    }
                    fieldCalculatorQuestion {
                      entity {
                        ... on drupalData_ParagraphCalculatorQuestionSection {
                          id
                          fieldTitle
                          fieldCalculatorQuestionItem {
                            entity {
                              ... on drupalData_ParagraphCalculatorQuestionItem {
                                id
                                fieldCopy {
                                  processed
                                }
                                fieldIcon {
                                  derivative(style: WEBP) {
                                    url
                                  }
                                  url
                                  alt
                                  title
                                }
                                fieldSubtitle
                                fieldTitle
                                fieldAverageAmountOfImages
                                fieldAverageCostPerImageEu
                                fieldAverageOperatorCost
                                fieldFixedCostPerMonth
                                fieldRecommendedProduct {
                                  entity {
                                    nid
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    fieldSavingsInfobox {
                      entity {
                        ... on drupalData_ParagraphSavingsInfobox {
                          entityBundle
                          fieldCopy {
                            processed
                          }
                          fieldHeadline {
                            processed
                          }
                          fieldLinkCta {
                            title
                            url {
                              path
                            }
                          }
                          id
                          uuid
                          fieldLessThan15Year
                          fieldLessThan1Year
                          fieldLessThan25Years
                          fieldLessThan2Years
                          fieldLessThan3Years
                          fieldLessThan6Months
                          fieldLessThan9Months
                          fieldMoreThan3Years
                        }
                      }
                    }
                  }
                }
              }
              fieldRecommendDevices {
                entity {
                  ... on drupalData_ParagraphRecommendDevices {
                    entityBundle
                    fieldCopy {
                      processed
                    }
                    fieldHeadline {
                      processed
                    }
                    id
                    uuid
                  }
                }
              }
              fieldFormCalculator {
                entity {
                  ... on drupalData_ParagraphFormCalculator {
                    entityBundle
                    fieldForm {
                      entity {
                        uuid
                      }
                    }
                    fieldHeadline {
                      processed
                    }
                    fieldDescription {
                      processed
                    }
                    fieldImage {
                      alt
                      derivative(style: WEBP) {
                        url
                      }
                      title
                      url
                    }
                  }
                }
              }
              entityMultisiteTranslations {
                entity {
                  entityTranslations {
                    entityLabel
                    entityLanguage {
                      id
                    }
                    ... on drupalData_Node {
                      status
                      path {
                        alias
                      }
                    }
                  }
                }
              }
              entityTranslations {
                entityLabel
                entityLanguage {
                  id
                }
                ... on drupalData_Node {
                  status
                  path {
                    alias
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return replaceLangCodes(data);
};
